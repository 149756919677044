import React from 'react';
import StyledHero from '../components/StyledHero';
import { graphql } from 'gatsby';
import PageWrapper from '../components/Layout/PageWrapper';
import Recipe from '../components/Recipes/Recipe';
import Title from '../components/Title';
import { Container, Col, Row } from 'react-bootstrap';
import Pager from '../components/Pager';
import SEO from "../components/seo";

export default class RecipeIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: props.data.recipes.nodes,
      recipes: props.data.recipes.nodes,
      // sortedRecipes: recipes.recipes,
      query: ""
    };
  }

  componentDidMount() {
    this.setState({
      recipes: this.props.data.recipes.nodes
    })
  }

  render() {
    const heroImage = this.props.data.recipesBcg.childImageSharp.fluid;
    const { recipes, filteredData, query, emptyQuery } = this.state;
    const allRecipes = recipes;

    const handleInputChange = event => {
      const query = event.target.value
      const posts = recipes || []   // return all filtered posts  
      const filteredData = posts.filter(post => {    // destructure data from post frontmatter    
        const { title, tags } = post
        return (
          // standardize data with .toLowerCase()      
          // return true if the description, title or tags      
          // contains the query string      
          // description.toLowerCase().includes(query.toLowerCase()) ||      
          title.toLowerCase().includes(query.toLowerCase()) ||
          (tags && tags
            .join("") // convert tags from an array to string        
            .toLowerCase()
            .includes(query.toLowerCase()))
        )
      })  // update state according to the latest query and results  
      this.setState({
        query, // with current query string from the `Input` event    
        filteredData, // with filtered data from posts.filter(post => (//filteredData)) above  
      })
    }

    const hasSearchResults = filteredData && query !== emptyQuery
    const posts = hasSearchResults ? filteredData : allRecipes

    return (
      <PageWrapper>
        <SEO title="Recepten" />
        <StyledHero img={heroImage} />
        <Container fluid className="justify-content-center">
          <Title title="mijn" subtitle="recepten" />
          <Container fluid className="mt-5 mb-5 justify-content-center d-flex">
            <input
              className="recipes-searchbar"
              type="text"
              aria-label="Search"
              placeholder="Zoek in mijn recepten"
              onChange={handleInputChange}
            />
          </Container>
          <Container fluid>
            <Row>
              {posts.length === 0 ? (
                <div><h4>Helaas zijn er nog geen recepten met deze zoekterm.</h4></div>
              ) : (
                  posts.map((post) => {
                    return (
                      <Col xs={12} sm={6} md={4} lg={3} className="pb-3" key={post.id}>
                        <Recipe recipe={post} />
                      </Col>
                    )
                  })
                )}
            </Row>
          </Container>
          <Pager pageContext={this.props.pageContext} page="recipes" />
        </Container>
      </PageWrapper>
    )
  }
};

export const recipeListQuery = graphql`
  query recipeListQuery($skip: Int!, $limit: Int!) {
    recipesBcg: file(relativePath: {eq: "recipesBcg.jpeg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    recipes: allContentfulRecipe(
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit) {
        nodes {
          id
          slug
          title
          tags
          featuredImage {
            fluid(quality: 100, maxHeight: 750) {
              srcWebp
            }
            title
          }
          date(formatString: "DD MMMM YYYY", locale: "nl")
        }
      }
  } 
`;
